
.correctResponse {
  color: green;
}

.failedResponse {
  color: red;
}

.App {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background: #00000014;
}

button {
  clear: both;
  padding: 10px;
  display: block;
  margin-top: 20px;
  background: #0000004d;
  font-size: 18px;
  border: 0;
  border-radius: 2px;
}

p {
  font-size: 18px;
}

input {
  padding: 5px;
  font-size: 16px;
}

.currentNumber {
  font-weight: bold;
  font-size: 36px;
}

.flex-grid-thirds {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.grid-header {
  font-weight: bold;
}

.flex-grid-thirds .col {
  width: 33.33%;
  border-bottom: 2px solid #0000006e;
  border-right: 2px solid #00000042;
  padding: 5px;
}

.resultText {
  background: #00800047;
  padding: 10px;
  font-size: 22px;
}